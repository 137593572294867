import React from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import tw from "twin.macro";

const Container = tw.div`relative max-w-screen-xl mx-auto pt-12 lg:py-24`;
const Content = tw.div``;
const Section = tw.div`mb-16 flex flex-col md:flex-row items-center`;
const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`w-full md:w-1/2 h-80 bg-cover bg-center rounded-lg`
]);
const TextContainer = tw.div`w-full md:w-1/2 px-6 md:px-12`;
const Subtitle = tw.h3`text-xl font-bold text-secondary-100`;
const Paragraph = tw.p`mt-2 text-sm leading-loose text-gray-700`;

const industries = {
  manufacturing: {
    title: "Manufacturing",
    subheading: "Optimize Operations & Drive Growth",
    description: "From predictive maintenance to intelligent automation, we help manufacturing businesses scale efficiently and securely.",
    imageSrc1: "/industries/mfgImage1.jpg",
    imageSrc2: "/industries/mfgImage2.jpg",
    challenges: [
      "Legacy systems slow down production and hinder digital transformation.",
      "Lack of real-time data prevents proactive decision-making.",
      "Manual workflows create inefficiencies, leading to delays and increased costs."
    ],
    solutions: [
      "Leverage business intelligence solutions to track production performance in real time.",
      "Integrate proactive digital maintenance to prevent downtime before it happens.",
      "Streamline operations with smart process automation for seamless efficiency."
    ]
  },
  construction: {
    title: "Construction",
    subheading: "Building Smarter, More Efficiently",
    description: "We streamline project management, enhance collaboration, and improve workflow efficiency in construction businesses.",
    imageSrc1: "/industries/constImage1.jpg",
    imageSrc2: "/industries/constImage2.jpg",
    challenges: [
      "Overbudget projects due to poor cost forecasting.",
      "Slow project execution caused by inefficient digital workflows.",
      "Collaboration gaps between field and office teams."
    ],
    solutions: [
      "Utilize AI powered cost estimation tools to improve budgeting accuracy.",
      "Implement cloud-based project management for real-time collaboration.",
      "Enable IoT driven automation to optimize building workflows."
    ]
  },
  logistics: {
    title: "Logistics & Supply Chain",
    subheading: "Efficiency, Optimization, and Cost Reduction",
    description: "Optimize fleet tracking, enhance supply chain visibility, and reduce costs through smart logistics solutions.",
    imageSrc1: "/industries/logImage1.jpg",
    imageSrc2: "/industries/logImage2.jpg",
    challenges: [
      "Limited fleet tracking capabilities increase operational costs.",
      "Lack of real-time supply chain visibility causes delays.",
      "Manual inventory tracking leads to data inconsistencies."
    ],
    solutions: [
      "Optimize fleet management with AI driven logistics tracking.",
      "Enhance supply chain transparency with real-time analytics.",
      "Use AI powered warehouse automation to streamline order fulfillment."
    ]
  },
  businessservices: {
    title: "Business Services",
    subheading: "Technology-Driven Solutions for Modern Businesses",
    description: "Leverage AI powered analytics, cybersecurity, and cloud infrastructure to scale your business securely.",
    imageSrc1: "/industries/serImage1.jpg",
    imageSrc2: "/industries/serImage2.jpg",
    challenges: [
      "Manual processes limit business growth potential.",
      "Cybersecurity risks leave sensitive data vulnerable.",
      "Lack of data driven insights leads to poor decision making."
    ],
    solutions: [
      "Leverage business analytics to optimize decision-making.",
      "Strengthen security frameworks with advanced compliance solutions.",
      "Scale operations with cloud-based infrastructure for remote teams."
    ]
  }
};

export default function IndustriesDetail() {
  const { industryName } = useParams();
  const industry = industries[industryName];

  return (
    <Container>
      <Content>
        <Section>
          <Image imageSrc={industry.imageSrc1} alt={`Innovative solutions in ${industry.title}`} />
          <TextContainer>
            <Subtitle>Industry Challenges</Subtitle>
            {industry.challenges.map((challenge, i) => (
              <Paragraph key={i}>❌ {challenge}</Paragraph>
            ))}
          </TextContainer>
        </Section>

        <Section id="solutions">
          <TextContainer>
            <Subtitle>How We Solve These Challenges</Subtitle>
            {industry.solutions.map((solution, i) => (
              <Paragraph key={i}>✅ {solution}</Paragraph>
            ))}
          </TextContainer>
          <Image imageSrc={industry.imageSrc2} alt={`Optimized solutions for ${industry.title}`} />
        </Section>
      </Content>
    </Container>
  );
}