import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
// import tw from "twin.macro";
// import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/MainHeader.js";
import Footer from "components/footers/MainFooter";
import MetaComponent from "components/common/meta";
import ExploringResults from "components/features/ExploringResults"

export default function SixMonthsResultsPage() {

  const meta = {
    title: "About Us | Empowering Businesses with Accessible Data and Technology Solutions | LariatCo",
    // description: "At LariatCo, we empower businesses to thrive by making complex data and technology solutions accessible and manageable.",
    // canonical: "https://www.lariatco.com/aboutus",
    // author: "LariatCo",
    // robots: "index, follow",
    // og: {
    //   site_name: "LariatCo",
    //   title: "About Us | Empowering Businesses with Accessible Data and Technology Solutions",
    //   description: "Discover how LariatCo makes complex data and technology solutions accessible, helping businesses manage operations efficiently and drive growth.",
    //   type: "website",
    //   url: "https://www.lariatco.com/aboutus",
    //   image: "https://www.lariatco.com/twogcard.png",
    // },
    // twitter: {
    //   card: "summary_large_image",
    //   title: "About Us | Empowering Businesses with Accessible Data and Technology Solutions",
    //   description: "At LariatCo, we empower businesses to thrive by making complex data and technology solutions accessible and manageable.",
    //   image: "https://www.lariatco.com/twogcard.png",
    // },
  };

  return (
    <AnimationRevealPage disabled>
      <MetaComponent meta={meta} />
      <Header />
    <ExploringResults />
      
      <Footer />
    </AnimationRevealPage>
  );
};

