import React from "react";
import { Link } from "react-router-dom";
import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading } from "components/misc/Headings.js";

const Container = tw.div`relative -mx-8`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 px-2 lg:px-0 lg:py-24`;
const HeroSection = tw.div`text-center py-16 bg-marketing-secslate`;
const HeroTitle = tw(SectionHeading)`text-3xl lg:pt-24 text-marketing-secseafoam md:text-4xl font-bold`;
const HeroDescription = tw.p`text-lg lg:pb-24 md:text-xl text-white mt-4 max-w-5xl mx-auto`;
const TwoColumn = tw.div`grid grid-cols-1 md:grid-cols-2 gap-8 mt-12`;
const Card = tw.div`w-full p-4 shadow-lg rounded-lg flex flex-col h-full`;
const CardImage = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`h-64 bg-cover bg-center rounded`
]);
const CardText = tw.div`flex flex-col flex-grow mt-4`;
const CardTitle = tw.h5`text-xl my-2 font-bold flex-grow`;
const CardAction = tw.button`rounded-full px-8 py-3 text-sm sm:text-base 
  bg-marketing-secslate items-center text-gray-100 
  hocus:bg-marketing-priaqua hocus:text-marketing-pricobolt
  font-bold shadow transition duration-300 
  focus:outline-none focus:shadow-outline`;

const CardP = tw.p`text-marketing-secslate mb-2`;
const CardH = tw.h3`text-lg font-semibold text-marketing-pricobolt`;
const CTASection = tw.div`mt-16 text-center bg-marketing-secslate p-10 rounded-lg`;
const CTAHeading = tw.h3`text-2xl text-white font-bold`;
const CTADescription = tw.p`text-lg text-white mt-2`;
const CTAButton = tw(Link)`inline-block mt-4 px-6 py-3 text-lg font-bold text-white bg-marketing-priaqua rounded-full shadow-lg hocus:bg-marketing-pricobolt`;

const caseStudies = [
  {
    id: "logistics",
    imageSrc: "https://filesblog.bizvibe.com/wp-content/webp-express/webp-images/uploads/2020/06/largest-trucking-companies.jpg.webp",
    company: "OIB Transport",
    industry: "Logistics",
    type: "System Integration",
    title: "60% Time Savings with System Integration",
    durationText: "Implemented Q1 2025",
    locationText: "South Bend, IN",
    description: "Streamlining trucking operations with automation.",
    highlights: [
      "Full system automation from load tender receipt to invoice payment receipt",
      "Reduced manual invoicing errors",
      "Integrated real-time tracking",
      "Implemented KPI metrics via a simple admin dashboard"
    ],
    metrics: [
      "60% time savings",
      "40% reduction in manual input errors",
      "Increased DOT compliance"
    ]
  }
];

export default function CaseStudyHome() {
  return (
    <Container>
      <HeroSection>
        <HeroTitle>Real World Transformation: How We Help Businesses Thrive</HeroTitle>
        <HeroDescription>Discover how our technology driven solutions improve efficiency, automate workflows, and fuel business growth. 
          See real world success stories where digital transformation has streamlined operations and maximized impact.</HeroDescription>
      </HeroSection>
      <Content>
        <TwoColumn>
          {caseStudies.map((study, index) => (
            <Card key={index}>
              <CardImage imageSrc={study.imageSrc} />
              <CardText>
                <CardH>{study.industry}</CardH>
                <CardTitle>{study.title}</CardTitle>
                <CardP>{study.locationText}</CardP>
                <Link to={`/case-studies/${study.id}`}>
                  <CardAction>Read Case Study</CardAction>
                </Link>
              </CardText>
            </Card>
          ))}
        </TwoColumn>

        {/* "More Case Studies Coming Soon" Section */}
        <CTASection>
          <CTAHeading>More Success Stories Coming Soon</CTAHeading>
          <CTADescription>We’re currently working with businesses in manufacturing, business services, and construction. Want to be featured as a tranformation showcase? Let's talk.</CTADescription>
          <CTAButton to="/contact">Talk to Us</CTAButton>
        </CTASection>
      </Content>
    </Container>
  );
}
