import React from "react";
import { Helmet } from "react-helmet-async";

export default function MetaComponent({ meta }) {
  return (
    <Helmet>
      <title>{meta?.title || "Default Title"}</title>
      {meta?.description && <meta name="description" content={meta.description} />}
      {meta?.keywords && <meta name="keywords" content={meta.keywords} />}
      {meta?.author && <meta name="author" content={meta.author} />}
      {meta?.robots && <meta name="robots" content={meta.robots} />}
      {meta?.canonical && <link rel="canonical" href={meta.canonical} />}

      {meta?.og?.site_name && <meta property="og:site_name" content={meta.og.site_name} />}
      {meta?.og?.title && <meta property="og:title" content={meta.og.title} />}
      {meta?.og?.description && <meta property="og:description" content={meta.og.description} />}
      {meta?.og?.type && <meta property="og:type" content={meta.og.type} />}
      {meta?.og?.url && <meta property="og:url" content={meta.og.url} />}
      {meta?.og?.image && <meta property="og:image" content={meta.og.image} />}

      {meta?.twitter?.card && <meta name="twitter:card" content={meta.twitter.card} />}
      {meta?.twitter?.title && <meta name="twitter:title" content={meta.twitter.title} />}
      {meta?.twitter?.description && <meta name="twitter:description" content={meta.twitter.description} />}
      {meta?.twitter?.image && <meta name="twitter:image" content={meta.twitter.image} />}
    </Helmet>
  );
}
