import React, { useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import EmailIllustrationSrc from "images/stock/contact4.jpg";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-2xl mx-auto py-12 md:py-12`;
const Column = tw.div`w-full max-w-2xl mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-auto md:h-auto`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;
const Heading = tw(SectionHeading)`mt-4 text-marketing-secslate text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-marketing-secslate`
const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0`
const Input = tw.input`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-marketing-pricobolt`
const TextArea = tw.textarea`h-72 sm:h-full resize-none mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-marketing-pricobolt`;
const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8 bg-marketing-secslate text-gray-100 hocus:bg-marketing-priaqua hocus:text-marketing-pricobolt`

export default ({
  subheading = "Contact Us",
  heading = <>Let's <span tw="text-marketing-pricobolt">START</span> a <span tw="text-marketing-pricobolt">CONVERSATION</span> </>,
  description = <>We're here to help simplify your challenges and turn your goals into reality. Whether you have questions about our services or are ready to get started, we'd love to hear from you.</>,
  submitButtonText = "Send",
  formAction = "#",
  formMethod = "get",
  textOnLeft = true,
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      // Send form data to the server
      const response = await fetch('/api/sendMessage', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData), // Send the form data
      });
  
      // Handle the server response
      if (response.ok) {
        const result = await response.json();
        alert("Message sent successfully!");
        console.log("Server Response:", result);
        // Reset the form after successful submission
        setFormData({ name: "", email: "", phone: "", message: "" });
      } else {
        const error = await response.json();
        console.error("Server Error:", error);
        alert(error.error || "Failed to send the message. Please try again.");
      }
    } catch (error) {
      console.error("Network Error:", error);
      alert("A network error occurred. Please check your connection and try again.");
    }
  };

  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          <Image imageSrc={EmailIllustrationSrc} />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            
            <Heading>{heading}</Heading>
            {description && <Description>{description}</Description>}
            <Form onSubmit={handleSubmit}>
            <Input id="name-input" type="text" name="name" placeholder="E.g. John Doe" value={formData.name} onChange={handleChange}/>
            <Input id="email-input" type="email" name="email" placeholder="E.g. john@mail.com" value={formData.email} onChange={handleChange}/>
            <Input id="phone-input" type="phone" name="phone" placeholder="E.g. 555-555-5555" value={formData.phone} onChange={handleChange}/>
            <TextArea id="message-input" name="message" placeholder="E.g. Tell us how we can assist you today" value={formData.message} onChange={handleChange}/>
              <SubmitButton type="submit">{submitButtonText}</SubmitButton>
            </Form>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
