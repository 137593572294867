import React, { useState, useEffect } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { SectionHeading as HeadingTitle } from "../misc/Headings.js";

const backgroundImages = [
  { src: '/industries/industriesOne.jpg', alt: 'Industry one - manufacturing worker' },
  { src: '/industries/industriesTwo.jpg', alt: 'Industry two - supply chain leader' },
  { src: '/industries/industriesThree.jpg', alt: 'Industry three - supply chain workers' },
  { src: '/industries/industriesFour.jpg', alt: 'Industry four - warehouse leaders' },
  { src: '/industries/industriesFive.jpg', alt: 'Industry five - construction worker' },
  { src: '/industries/industriesSix.jpg', alt: 'Industry six - manufacturing worker' }
];

const HeroContainer = tw.div`
  relative -mx-8 mt-8 mb-0 h-[70vh] sm:h-[60vh] md:h-[60vh] lg:h-[90vh] min-h-[50vh] h-screen 
  flex flex-col justify-center items-center text-center text-white overflow-hidden
`;

const RotatingBackground = styled.img`
  ${tw`absolute inset-0 w-full h-full object-cover -z-10 transition-opacity duration-1000`}
  filter: brightness(20%);
  opacity: ${(props) => (props.visible ? 1 : 0)};
`;

const HeroContent = tw.div`relative max-w-3xl px-6 z-10`;
const Title = tw.h1`text-4xl lg:text-6xl text-marketing-pripaper font-bold leading-tight`;
const Subheading = tw.p`mt-4 text-lg text-marketing-secseafoam lg:text-xl max-w-2xl`;
const CTAButton = styled.a`
  ${tw`inline-block mt-6 px-8 py-4 text-lg font-bold text-white bg-marketing-secseafoam rounded-lg transition duration-300 hover:bg-marketing-pricobolt`}
  position: relative;
  z-index: 10;
`;

const Container = tw.div`relative max-w-screen-xl mx-auto py-20 lg:py-24`;
const HeadingInfoContainer = tw.div`text-center`;
const Content = tw.div`mt-16`;
const Card = styled.div(props => [
  tw`mt-24 md:flex justify-center items-center`,
  props.reversed ? tw`flex-row-reverse` : "flex-row"
]);

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded md:w-1/2 lg:w-5/12 xl:w-1/3 flex-shrink-0 h-80 md:h-144 bg-cover bg-center mx-4 sm:mx-8 md:mx-4 lg:mx-8`
]);

const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
const Subtitle = tw.div`font-bold tracking-wide text-marketing-secslate`;
const IndustryTitle = tw.h4`text-3xl font-bold text-marketing-pricobolt`;
const Description = tw.p`mt-2 text-sm leading-loose text-black`;
const Link = tw.a`inline-block mt-4 text-sm text-marketing-pricobolt font-bold cursor-pointer transition duration-300 border-b-2 border-transparent hover:border-marketing-priaqua`;

const industries = {
  manufacturing: {
    title: "Manufacturing",
    subheading: "Optimize Operations & Drive Growth",
    description: "From automation to real-time production insights, we help manufacturing businesses scale with confidence.",
    imageSrc: "/industries/industriesManufacturing.jpg",
    alt: "Worker in a manufacturing plant"
  },
  construction: {
    title: "Construction",
    subheading: "Building Smarter, More Efficiently",
    description: "We streamline project management, enhance collaboration, and improve workflow efficiency in construction businesses.",
    imageSrc: "/industries/industriesConstruction.jpg",
    alt: "Workers on a construction site"
  },
  logistics: {
    title: "Logistics & Supply Chain",
    subheading: "Efficiency, Optimization, and Cost Reduction",
    description: "Optimize fleet tracking, enhance supply chain visibility, and reduce costs through smart logistics solutions.",
    imageSrc: "/industries/industriesLogistics.jpg",
    alt: "Warehouse site"
  },
  businessservices: {
    title: "Business Services",
    subheading: "Technology-Driven Solutions for Modern Businesses",
    description: "Leverage cloud collaboration, AI-powered analytics, and cybersecurity to scale your business securely.",
    imageSrc: "/industries/industriesBusiness.jpg",
    alt: "Business service employees working together"
  }
};

export default function IndustriesFeature() {
  const [currentImage, setCurrentImage] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prev) => (prev + 1) % backgroundImages.length);
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <HeroContainer aria-label={backgroundImages[currentImage].alt}>
        {backgroundImages.map((bg, index) => (
          <RotatingBackground
            key={index}
            src={bg.src}
            alt={bg.alt}
            visible={index === currentImage}
            fetchpriority={index === currentImage ? "high" : "low"}
          />
        ))}
        <HeroContent>
          <Title>Technology Solutions for Manufacturing, Logistics, Construction, & More</Title>
          <Subheading>
            We help businesses modernize, automate, and secure their operations with tailored technology solutions.
          </Subheading>
          <CTAButton href="#industries-listing">Find Your Industry & Discover Tailored Solutions</CTAButton>
        </HeroContent>
      </HeroContainer>

      <Container>
        <HeadingInfoContainer>
          <HeadingTitle>Industries We Empower</HeadingTitle>
        </HeadingInfoContainer>

        <Content id="industries-listing">
          {Object.entries(industries).map(([key, industry], i) => (
            <Card key={key} reversed={i % 2 === 1}>
              <Image imageSrc={industry.imageSrc} aria-label={industry.alt} />
              <Details>
                <Subtitle>{industry.subheading}</Subtitle>
                <IndustryTitle>{industry.title}</IndustryTitle>
                <Description>{industry.description}</Description>
                <Link href={`/industries/${key}`}>Learn More</Link>
              </Details>
            </Card>
          ))}
        </Content>
      </Container>
    </>
  );
}
