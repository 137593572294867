import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
// import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/MainHeader.js";
import Footer from "components/footers/MainFooter";
import ContactUsForm from "components/forms/ContactPageForm";
// import Subscribe from "components/misc/Subscribe"
import ContactDetails from "components/cards/ContactPageDetails";
import { Helmet } from "react-helmet-async";

export default () => {
  return (
    <AnimationRevealPage disabled>
      <Helmet>
        <title>Contact Us | Connect with LariatCo for Expert Assistance</title>
        {/* <meta name="description" content="Reach out to LariatCo for expert assistance. Contact us via phone at 877-268-6667, email at support@lariat.co or sales@lariat.co, or visit us at PO Box 1118, Hardwick, VT 05843." />
        <link rel="canonical" href="https://www.lariatco.com/contact" />
        <meta name="author" content="LariatCo" />

        <meta property="og:site_name" content="LariatCo" />
        <meta property="og:title" content="Contact Us | Connect with LariatCo for Expert Assistance" />
        <meta property="og:description" content="Get in touch with LariatCo for expert assistance. Call us at 877-268-6667, email support@lariat.co or sales@lariat.co, or visit us at PO Box 1118, Hardwick, VT 05843." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.lariatco.com/contact" />
        <meta property="og:image" content="https://www.lariatco.com/twogcard.png" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Contact Us | Connect with LariatCo for Expert Assistance" />
        <meta name="twitter:description" content="Need assistance? Contact LariatCo at 877-268-6667, email support@lariat.co or sales@lariat.co, or visit us at PO Box 1118, Hardwick, VT 05843." />
        <meta name="twitter:image" content="https://www.lariatco.com/twogcard.png" /> */}
      </Helmet>
            <Header />
            <ContactUsForm />
            {/* <Subscribe /> */}
            <ContactDetails />
            <Footer />
          </AnimationRevealPage>
        );
      };
