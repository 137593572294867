import React, { useState } from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import ReactModalAdapter from "../../helpers/ReactModalAdapter.js";
// import ResponsiveVideoEmbed from "../../helpers/ResponsiveVideoEmbed.js";
import {  Subheading as SubheadingBase } from "components/misc/Headings.js";
// add back in when we get video
// import { ReactComponent as PlayIcon } from "feather-icons/dist/icons/play-circle.svg";
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-1.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "../../images/dot-pattern.svg";
import DesignIllustration from "../../images/stock/heroOption2.jpg";

const Container = tw.div`relative -mx-8 -mt-8 flex bg-blue-800 items-center justify-center`
const HeroContainer = tw.div`z-20 relative px-6 sm:px-8 mx-auto h-full flex flex-col mb-20`;
const TwoColumn = tw.div`flex flex-col lg:flex-row md:items-center max-w-screen-2xl mx-auto py-20 md:py-24`;
const LeftColumn = tw.div`relative lg:w-6/12 lg:pr-12 flex-shrink-0 text-left`;
const RightColumn = tw.div`relative mt-12 lg:mt-0 flex flex-col items-center justify-center`;

const Subheading = tw(SubheadingBase)`text-left text-2xl text-marketing-secseafoam`;
const Heading = tw.h1`mb-6 text-white text-3xl md:text-5xl leading-snug max-w-3xl`;
const Paragraph = tw.p`mb-4 md:mb-8 text-sm lg:text-base font-medium text-marketing-pripaper max-w-lg`;
const Actions = tw.div`flex flex-col items-center sm:flex-row justify-center lg:justify-start mt-8`;
const PrimaryButton = tw.button`md:mb-8 mb-4 font-bold mr-4 px-8 lg:px-10 py-3 rounded bg-marketing-secseafoam text-gray-100 hocus:bg-marketing-priaqua hocus:text-marketing-pricobolt focus:shadow-outline focus:outline-none transition duration-300`;
// add in when we get video
// const WatchVideoButton = styled.button`
//   ${tw`mt-4 sm:mt-0 sm:ml-8 flex items-center text-secondary-300 transition duration-300 hocus:text-primary-400 focus:outline-none`}
//   .playIcon {
//     ${tw`stroke-1 w-12 h-12`}
//   }
//   .playText {
//     ${tw`ml-2 font-medium`}
//   }
// `;

const Span = tw.a`font-bold underline text-marketing-pripaper`
const IllustrationContainer = tw.div`flex justify-center md:justify-end mb-24 items-center relative mt-12 max-w-3xl lg:max-w-none h-full min-h-[200px]`;
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none opacity-15 absolute left-0 bottom-0 h-64 w-64 transform -translate-x-2/3  -z-10`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none fill-current text-primary-500 opacity-25 absolute w-32 h-32 right-0 bottom-0 transform translate-x-10 translate-y-10 -z-10`}
`;
const StyledModal = styled(ReactModalAdapter)`
  &.mainHeroModal__overlay {
    ${tw`fixed inset-0 z-50`}
  }
  &.mainHeroModal__content {
    ${tw`xl:mx-auto m-4 sm:m-16 max-w-screen-xl absolute inset-0 flex justify-center items-center rounded-lg bg-gray-200 outline-none`}
  }
  .content {
    ${tw`w-full lg:p-16`}
  }
`;
const CloseModalButton = tw.button`absolute top-0 right-0 mt-8 mr-8 hocus:text-primary-500`;

export default ({
  heading = "Harness Your Potential",
  subheading = "Empowering Your Business Through Technology & Data Enhancements",
  subheading2 = "Scalable. Secure. Simplified. ",
  subheading3 = "Unlock Growth with Tailored Technology Solutions",
 description=<>Your business is built on expertise and intuition, but outdated technology and disconnected systems create obstacles. LariatCo specializes in <Span>digital transformation</Span>, <Span>business intelligence</Span>, and <Span>technology consulting</Span> for SMBs, helping your business eliminate inefficiencies, drive growth, and future-proof operations without the complexity.</>,
 description1= "Schedule a free consultation to learn how we can help your business thrive.",
 primaryButtonText="Get Started",
 primaryButtonText2="Explore Solutions",
  primaryButtonUrl="/scheduling",
  primaryButtonUrl2="/services",
  // taken out until we have a video
  // watchVideoButtonText="Watch Video",
  // watchVideoYoutubeUrl="https://www.youtube.com/embed/_GuOjXYl5ew",
  imageSrc=DesignIllustration,
  imageCss=null,
  imageDecoratorBlob = false,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const toggleModal = () => setModalIsOpen(!modalIsOpen);

  return (
    <>
      <Container>
      <HeroContainer >
        <TwoColumn>
          <LeftColumn>
            <Heading>{heading}</Heading>
            <Subheading>{subheading}</Subheading>
            <Paragraph>{description}</Paragraph>
            <Subheading>{subheading2}</Subheading>
            <Subheading>{subheading3}</Subheading>
            <Paragraph>{description1}</Paragraph>
            <Actions>
              <PrimaryButton as="a" href={primaryButtonUrl}>{primaryButtonText}</PrimaryButton>
              <PrimaryButton as="a" href={primaryButtonUrl2}>{primaryButtonText2}</PrimaryButton>
              {/* taken out until we have a video */}
              {/* <WatchVideoButton onClick={toggleModal}>
                <span className="playIconContainer">
                  <PlayIcon className="playIcon" />
                </span>
                <span className="playText">{watchVideoButtonText}</span>
              </WatchVideoButton> */}
            </Actions>
          </LeftColumn>
          <RightColumn>
            <IllustrationContainer>
              <img
                css={imageCss}
                src={imageSrc}
                alt="Hero"
              />
              {imageDecoratorBlob && <DecoratorBlob2 />}
            </IllustrationContainer>
          </RightColumn>
        </TwoColumn>
        </HeroContainer>
        <DecoratorBlob1 />
        <StyledModal
          closeTimeoutMS={300}
          className="mainHeroModal"
          isOpen={modalIsOpen}
          onRequestClose={toggleModal}
          shouldCloseOnOverlayClick={true}
        >
          <CloseModalButton onClick={toggleModal}>
            <CloseIcon tw="w-6 h-6" />
          </CloseModalButton>
          {/* <div className="content">
            <ResponsiveVideoEmbed url={watchVideoYoutubeUrl} tw="w-full" />
          </div> */}
        </StyledModal>
      </Container>
    </>
  );
};
