import React from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import tw from "twin.macro";
import { ReactComponent as CheckIcon } from "feather-icons/dist/icons/check-circle.svg";

/* Styled Components */
const HeroContainer = styled.div`
  ${tw`relative -mx-8 mt-8 mb-0 mt-6 flex items-center justify-center text-center py-24 md:py-40 lg:py-48`}
  background-image: url(${props => props.imageSrc});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Dark overlay for better readability */
  }
`;

const HeroOverlay = tw.div`absolute inset-0 bg-black bg-opacity-50`;
const HeroContent = tw.div`relative px-4 z-10 max-w-3xl mx-auto text-white`;
const Subheading = tw.p`text-lg uppercase font-semibold tracking-wider text-marketing-priaqua`;
const Title = tw.h1`text-3xl sm:text-4xl md:text-5xl font-bold leading-tight`;
const Paragraph = tw.p`mt-4 text-lg md:text-xl text-gray-300`;
const CTAButton = tw.a`inline-block mt-6 px-8 py-4 text-lg font-bold text-white bg-marketing-secseafoam rounded-full shadow-lg hover:bg-marketing-pricobolt transition duration-300`;

const Container = tw.div`relative -mx-4 md:-mx-6 lg:-mx-8`;
const Content = tw.div`max-w-screen-xl mx-auto py-12 md:py-16 lg:py-24 px-4 lg:px-8`;
const BackgroundWrapper = tw.div`bg-marketing-secseafoam text-center mx-2 px-6 py-4 rounded-lg shadow-lg w-full max-w-6xl`;
const Section = tw.div`py-12 `;
const SectionTitle = tw.h3`text-lg sm:text-xl text-marketing-pricobolt font-bold mb-4`;
const ResultsTitle = tw.h3`text-lg sm:text-2xl text-marketing-pricobolt font-bold mb-8`;
const TwoColumn = tw.div`flex flex-col lg:flex-row gap-8`;
const Column = tw.div`w-full lg:w-1/2`;
const BulletPoint = tw.div`flex items-center mt-3 sm:mt-4`;
const BulletText = tw.p`ml-2 text-base sm:text-lg text-marketing-secslate`;
const ChalBox =tw.div`text-red-500 w-6 h-6 mr-2`
const SolBox =tw.div`text-blue-500 w-6 h-6 mr-2`
const ResBox =tw.div`text-marketing-pricobolt w-6 h-6 mr-2`
const UneBox =tw.div`text-green-500 w-6 h-6 mr-2`
const FutBox =tw.div`text-purple-500 w-6 h-6 mr-2`

const caseStudies = [
  {
    id: "logistics",
    imageSrc: "https://filesblog.bizvibe.com/wp-content/webp-express/webp-images/uploads/2020/06/largest-trucking-companies.jpg.webp",
    company: "OIB Transport",
    industry: "Logistics",
    type: "System Integration",
    title: "60% Time Savings with System Integration",
    durationText: "Implemented Q1 2025",
    locationText: "South Bend, IN",
    description: "A custom built automation system transformed logistics operations , cutting admin time and reducing errors.",
    challenge: [
      "Client relied on manual processes and Excel spreadsheets, making it difficult to track operations efficiently.",
      "The lack of visibility meant that administrative staff spent excessive time on maintaining workflows rather than growing the business.",
      "Errors in invoicing and compliance tracking led to delays and unnecessary costs."
    ],
    solution: [
      "Developed a fully automated system to handle load management, operational workflows, invoicing, and payment tracking.",
      "Created a custom dashboard for real-time operational visibility, allowing the owner to track business performance at a glance.",
      "Used cost-effective, value-driven technology to implement a solution far below traditional market costs."
    ],
    results: [
      "60% time savings by automating manual workflows.",
      "30% cost savings, including better tax optimization through improved financial tracking.",
      "Significant reduction in invoicing errors, improving cash flow and compliance."
    ],
    unexpectedBenefits: [
      "The client was surprised that a high-quality, automated system could be built at such a low cost.",
      "They had assumed enterprise-level automation was out of reach due to funding constraints."
    ],
    futureGrowth: [
      "With a streamlined system, the Client can now scale operations and add more drivers without increasing administrative workload.",
      "If the owner eventually chooses to exit the business, the system's data collection will provide a valuable asset for selling their book of business."
    ]
  }
];

const CaseStudyDetails = () => {
  const { id } = useParams();
  const study = caseStudies.find(study => study.id === id);

  if (!study) return <p className="text-center text-red-500">Case study not found.</p>;

  return (
    <Container>
      {/* Hero Section with Background Image */}
      <HeroContainer imageSrc={study.imageSrc} aria-label={`Background image for ${study.title}`}>
        <HeroOverlay />
        <HeroContent>
          <Subheading>{study.industry} Case Study</Subheading>
          <Title>{study.title}</Title>
          <Paragraph>{study.description}</Paragraph>
          <CTAButton href="#results">See Results</CTAButton>
        </HeroContent>
      </HeroContainer>

      {/* Existing Content Below */}
      <Content id="results">
        {/* Challenge & Solution (Side-by-Side) */}
        <Section tw="bg-marketing-secslate text-white">
          <TwoColumn>
            <Column>
              <SectionTitle>🚨 The Challenge</SectionTitle>
              {study.challenge.map((item, index) => (
                <BulletPoint key={index}>
                  <ChalBox><CheckIcon /></ChalBox>
                  <BulletText>{item}</BulletText>
                </BulletPoint>
              ))}
            </Column>
            <Column>
              <SectionTitle>🔧 The Solution</SectionTitle>
              {study.solution.map((item, index) => (
                <BulletPoint key={index}>
                  <SolBox><CheckIcon /></SolBox>
                  <BulletText>{item}</BulletText>
                </BulletPoint>
              ))}
            </Column>
          </TwoColumn>
        </Section>

        {/* Results & Impact (Full-Width Row) */}
        <BackgroundWrapper>
        <Section >
          <ResultsTitle>📊 Results & Impact</ResultsTitle>
          {study.results.map((item, index) => (
            <BulletPoint key={index} >
              <ResBox><CheckIcon /></ResBox>
              <BulletText>{item}</BulletText>
            </BulletPoint>
          ))}
        </Section>
        </BackgroundWrapper>

        {/* Unexpected Benefits & Future Growth (Full-Width Row) */}
        <Section tw="bg-marketing-pricoal text-white">
          <TwoColumn>
            <Column>
              <SectionTitle>✨ Unexpected Benefits</SectionTitle>
              {study.unexpectedBenefits.map((item, index) => (
                <BulletPoint key={index}>
                  <UneBox><CheckIcon /></UneBox>
                  <BulletText>{item}</BulletText>
                </BulletPoint>
              ))}
            </Column>
            <Column>
              <SectionTitle>📈 Future Growth</SectionTitle>
              {study.futureGrowth.map((item, index) => (
                <BulletPoint key={index}>
                  <FutBox><CheckIcon /></FutBox>
                  <BulletText>{item}</BulletText>
                </BulletPoint>
              ))}
            </Column>
          </TwoColumn>
        </Section>
      </Content>
    </Container>
  );
};

export default CaseStudyDetails;
