import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Img from "images/stock/aboutHero3.jpg";

const Container = tw.div`
  relative -mx-8 mt-8 mb-0 h-[70vh] sm:h-[60vh] md:h-[60vh] lg:h-[90vh] min-h-[50vh] overflow-hidden
`;

const BackgroundImage = styled.img`
  ${tw`absolute inset-0 w-full h-full object-cover -z-10`}
  filter: brightness(20%);
`;

const HeroContainer = tw.div`relative z-10 px-6 sm:px-8 mx-auto h-full flex flex-col`;
const Content = tw.div`px-4 flex flex-1 flex-col justify-center items-center`;

const Heading = styled.h1`
  ${tw`text-3xl text-center sm:text-4xl lg:text-5xl xl:text-6xl font-black text-marketing-secseafoam leading-snug -mt-24 sm:mt-0`}
`;

const PrimaryAction = tw(Link)`
  rounded-full px-8 py-3 mt-10 text-sm sm:text-base 
  bg-marketing-secslate text-gray-100 
  hocus:bg-marketing-priaqua hocus:text-marketing-pricobolt
  font-bold shadow transition duration-300 
  focus:outline-none focus:shadow-outline
`;

const AboutHero = () => {
  return (
    <Container>
      <BackgroundImage
        src={Img}
        alt="About Background"
        fetchpriority="high"
      />
      <HeroContainer>
        <Content>
          <Heading>
            Empowering Businesses Through
            <br />
            Simplicity and Innovation
          </Heading>
          <PrimaryAction to="/contact">Contact Us</PrimaryAction>
        </Content>
      </HeroContainer>
    </Container>
  );
};

export default AboutHero;
