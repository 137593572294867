import React from "react";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "../../helpers/AnimationRevealPage";
import Header from "../../components/headers/MainHeader";
import Blog from "components/blogs/BlogListing";
import Footer from "../../components/footers/MainFooter";
import { Helmet } from "react-helmet-async";

export default () => {

  return (
    <>
    <AnimationRevealPage disabled>
    <Helmet>
      {/* <title>Insights & Strategies | LariatCo Blog</title>
      <meta name="description" content="Stay ahead with expert insights, strategies, and industry trends from LariatCo. Explore topics on business intelligence, technology, cybersecurity, and innovation." />
      <link rel="canonical" href="https://www.lariatco.com/blog" />
      <meta name="author" content="LariatCo" />

      <meta property="og:site_name" content="LariatCo" />
      <meta property="og:title" content="Insights & Strategies | LariatCo Blog" />
      <meta property="og:description" content="Discover the latest insights and expert strategies from LariatCo. Explore topics on technology, cybersecurity, business intelligence, and industry innovations." />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://www.lariatco.com/blog" />
      <meta property="og:image" content="https://www.lariatco.com/twogcard.png" />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="Insights & Strategies | LariatCo Blog" />
      <meta name="twitter:description" content="Stay informed with expert insights and strategies from LariatCo. Explore the latest in business intelligence, technology, and cybersecurity." />
      <meta name="twitter:image" content="https://www.lariatco.com/twogcard.png" /> */}
    </Helmet>

    <Header />
    <Blog />
      <Footer />
    </AnimationRevealPage>
    </>
  );
}
