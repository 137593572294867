import MainHeader from 'components/headers/MainHeader'
import React from 'react'
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import { Helmet } from "react-helmet-async";

const Div = tw.div`w-screen h-screen -mx-8 mt-8 overflow-hidden flex items-center justify-center`

const IframeWrapper = tw.div`w-full h-full`;

const Intro = () => {
  return (
    <>
    <AnimationRevealPage disabled>
    <Helmet>
      <title>Schedule a Consultation | LariatCo</title>
    </Helmet>

    <MainHeader />
    <Div>
      <IframeWrapper>
        <iframe
          src="https://outlook.office365.com/owa/calendar/LariatCo2@lariatco.com/bookings/"
          width="100%"
          height="100%"
          style={{ border: 0, overflow: "hidden" }}
          title="Booking Information"
        ></iframe>
      </IframeWrapper>
    </Div>
    </AnimationRevealPage>
    </>
  )
}

export default Intro