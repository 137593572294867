import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import tw from "twin.macro";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";

const Container = tw.div`relative mt-16 w-full`;
const Content = tw.div`max-w-screen-xl mx-auto pt-5 pb-20 lg:pt-5 pb-20`;
const FormContainer = styled.div`
  ${tw`p-8 sm:p-10 md:p-12 bg-marketing-secseafoam text-marketing-secslate rounded-lg relative`}
  h2 {
    ${tw`text-3xl text-center sm:text-4xl font-bold`}
  }
  h3 {
    ${tw`text-2xl text-center sm:text-2xl font-bold mb-4 `}
  }
`;

const QuestionContainer = tw.div`mb-6`;
const CheckboxLabel = tw.label`block mb-4`;
const CheckboxInput = tw.input`mr-2 size-4`;
const TwoColumn = tw.div`flex flex-col sm:flex-row justify-between mt-6`;
const Column = tw.div`sm:w-6/12 sm:px-6 sm:my-2 flex flex-col mr-6`;
const Divider = tw.div`border-l border-marketing-secslate mx-4`;
const TranCont = tw.div`flex justify-between `;

const NextButton = tw.button`w-full sm:w-32 mt-6 py-3 mr-3 bg-marketing-secslate text-white rounded-full font-bold tracking-wide shadow-lg uppercase 
text-sm transition duration-300 transform focus:outline-none focus:shadow-outline hover:bg-marketing-pricobolt hocus:-translate-y-px hocus:shadow-xl`;
const GoBackButton = tw.button`w-full sm:w-32 mt-6 py-3 bg-marketing-secslate text-white rounded-full font-bold tracking-wide shadow-lg uppercase 
text-sm transition duration-300 transform focus:outline-none focus:shadow-outline hover:bg-marketing-pricobolt hocus:text-white hocus:-translate-y-px hocus:shadow-xl`;

const ButtonContainer = tw.div`flex flex-col items-center space-y-4 mt-4`;
const OptionButton = tw(PrimaryButtonBase)`w-64 py-3 rounded-full font-bold tracking-wide shadow-lg uppercase text-sm transition duration-300 transform 
focus:outline-none focus:shadow-outline hocus:-translate-y-px hocus:shadow-xl bg-marketing-secslate text-gray-100 hocus:bg-white hocus:text-marketing-pricobolt`;

const QuestionnaireForm = () => {
  const [step, setStep] = useState(1);
  const [selectedCategories, setSelectedCategories] = useState([]); // Store selected checkboxes
  const navigate = useNavigate();

  const handleExploreClick = () => navigate("/business-assessment");
  const handleNextStep = () => setStep(step + 1);
  const handleGoBack = () => setStep(step - 1);

  // Handle checkbox selection independently
  const handleCheckboxChange = (category, statement) => {
    const selectedOption = { category, statement };
  
    setSelectedCategories((prev) => {
      // Check if the same selection exists
      const exists = prev.some(
        (option) => option.category === category && option.statement === statement
      );
  
      if (exists) {
        // Remove the selection if it already exists
        return prev.filter(
          (option) => !(option.category === category && option.statement === statement)
        );
      } else {
        // Add the new selection
        return [...prev, selectedOption];
      }
    });
  };

  // Handle navigation to separate results pages
  const handleResultsNavigation = (resultType) => {
    navigate(`/${resultType}`, { state: { selectedCategories } });
  };  

  return (
    <Container>
      <Content>
        <FormContainer>
          <h2>Find My Solution</h2>

          {step === 1 && (
            <ButtonContainer>
              <h3>Lets define exactly what you are looking for? In less than 30 seconds, we'll find the best solution for your business.</h3>
              <OptionButton onClick={handleNextStep}>Yes, guide me!</OptionButton>
              <OptionButton onClick={handleExploreClick}>No, I'm Just Looking</OptionButton>
            </ButtonContainer>
          )}

          {step === 2 && (
            <QuestionContainer>
              <TwoColumn>
                <Column>
                  <h3>Strategy</h3>
                  <CheckboxLabel>
                    <CheckboxInput
                      type="checkbox"
                      checked={selectedCategories.some(option => option.statement === "We need help defining our technology strategy and priorities.")}
                      onChange={() => handleCheckboxChange("strategic", "We need help defining our technology strategy and priorities.")}
                    />
                    We need help defining our technology strategy and priorities.
                  </CheckboxLabel>

                  <CheckboxLabel>
                    <CheckboxInput
                      type="checkbox"
                      checked={selectedCategories.some(option => option.statement === "We want better data insights to drive strategic decisions.")}
                      onChange={() => handleCheckboxChange("strategic", "We want better data insights to drive strategic decisions.")}
                    />
                    We want better data insights to drive strategic decisions.
                  </CheckboxLabel>

                  <CheckboxLabel>
                    <CheckboxInput
                      type="checkbox"
                      checked={selectedCategories.some(option => option.statement === "We need a roadmap for integrating or upgrading our technology stack.")}
                      onChange={() => handleCheckboxChange("strategic", "We need a roadmap for integrating or upgrading our technology stack.")}
                    />
                    We need a roadmap for integrating or upgrading our technology stack.
                  </CheckboxLabel>

                  <CheckboxLabel>
                    <CheckboxInput
                      type="checkbox"
                      checked={selectedCategories.some(option => option.statement === "We need scalable technology to support business growth.")}
                      onChange={() => handleCheckboxChange("strategic", "We need scalable technology to support business growth.")}
                    />
                    We need scalable technology to support business growth.
                  </CheckboxLabel>
                </Column>

                <Divider />

                <Column>
                  <h3>Execution</h3>
                  <CheckboxLabel>
                    <CheckboxInput
                      type="checkbox"
                      checked={selectedCategories.some(option => option.statement === "We know what we need but need help choosing the right tools.")}
                      onChange={() => handleCheckboxChange("execution", "We know what we need but need help choosing the right tools.")}
                    />
                    We know what we need but need help choosing the right tools.
                  </CheckboxLabel>

                  <CheckboxLabel>
                    <CheckboxInput
                      type="checkbox"
                      checked={selectedCategories.some(option => option.statement === "We need help integrating, securing, or optimizing our systems.")}
                      onChange={() => handleCheckboxChange("execution", "We need help integrating, securing, or optimizing our systems.")}
                    />
                    We need help integrating, securing, or optimizing our systems.
                  </CheckboxLabel>

                  <CheckboxLabel>
                    <CheckboxInput
                      type="checkbox"
                      checked={selectedCategories.some(option => option.statement === "We need to centralize or modernize our data infrastructure.")}
                      onChange={() => handleCheckboxChange("execution", "We need to centralize or modernize our data infrastructure.")}
                    />
                    We need to centralize or modernize our data infrastructure.
                  </CheckboxLabel>

                  <CheckboxLabel>
                    <CheckboxInput
                      type="checkbox"
                      checked={selectedCategories.some(option => option.statement === "We need ongoing technical leadership without hiring full-time staff.")}
                      onChange={() => handleCheckboxChange("execution", "We need ongoing technical leadership without hiring full-time staff.")}
                    />
                    We need ongoing technical leadership without hiring full-time staff.
                  </CheckboxLabel>
                </Column>
              </TwoColumn>

              <TranCont>
                <GoBackButton onClick={handleGoBack}>Go Back</GoBackButton>
                <NextButton onClick={handleNextStep}>Next</NextButton>
              </TranCont>
            </QuestionContainer>
          )}

          {step === 3 && (
            <QuestionContainer>
              <h3>How Soon Do You Need a Solution?</h3>
              <ButtonContainer>
                <OptionButton onClick={() => handleResultsNavigation("immediate-results")}>Immediately</OptionButton>
                <OptionButton onClick={() => handleResultsNavigation("six-months-results")}>Within 6 months</OptionButton>
                <OptionButton onClick={() => handleResultsNavigation("exploring-results")}>Just Exploring</OptionButton>
              </ButtonContainer>

              <TranCont>
                <GoBackButton onClick={handleGoBack}>Go Back</GoBackButton>
              </TranCont>
            </QuestionContainer>
          )}
        </FormContainer>
      </Content>
    </Container>
  );
};

export default QuestionnaireForm;
