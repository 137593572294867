import React from "react";
import tw from "twin.macro";

import Header from "components/headers/MainHeader.js";
import Footer from "components/footers/MainFooter";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Helmet } from "react-helmet-async";

const PageContainer = tw.div`flex flex-col items-center justify-center min-h-screen pt-24 px-6`;
const ContactContainer = tw.div`w-full max-w-6xl bg-gray-200 shadow-lg rounded-lg p-6 mb-6 md:mb-12`;  // Match width, add bottom spacing
const ContactInfo = tw.div`w-full md:w-1/2 `;  // Centers the content within the box
const ContentContainer = tw.div`flex flex-col md:flex-row justify-between w-full max-w-6xl gap-6`; // Add spacing for better responsiveness
const InfoContainer = tw.div`flex flex-col justify-between w-full md:w-1/2 p-6 mb-12 bg-white shadow-lg rounded-lg`; // Removed `m-2` for cleaner spacing
const Title = tw.h2`text-2xl font-bold text-marketing-pricobolt mb-4`;
const Description = tw.p`text-marketing-secslate mb-4`;
const Button = tw.button`px-6 py-3 mt-4 bg-blue-500 text-white font-bold rounded-lg hover:bg-blue-700 transition duration-300`;
const Phone = tw.a`text-blue-600 hover:text-blue-800 transition duration-300`
const Email = tw.a`text-blue-600 hover:text-blue-800 transition duration-300`
const Paragraph = tw.p``

export default () => {
  return (
    <AnimationRevealPage disabled>
      <Helmet>
        <title>Schedule a Call | LariatCo</title>
        {/* <meta name="description" content="Book a consultation with LariatCo. Choose between a quick 20-minute introduction or a deep-dive 60-minute discovery call to explore how our solutions fit your business needs." />
        <meta name="author" content="LariatCo" />
        <meta name="keywords" content="business consultation, technology solutions, digital transformation, business efficiency, scheduling call, IT strategy call" />

        <meta property="og:site_name" content="LariatCo" />
        <meta property="og:title" content="Schedule a Call | LariatCo" />
        <meta property="og:description" content="Select a time to connect with LariatCo. Schedule a 20-minute intro call or a 60-minute strategy session to discuss tailored solutions for your business." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.lariatco.com/schedule" />
        <meta property="og:image" content="https://www.lariatco.com/scheduling-image.png" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Schedule a Call | LariatCo" />
        <meta name="twitter:description" content="Book a call with LariatCo to discuss tailored business solutions. Choose between a quick introduction or an in-depth discovery session." />
        <meta name="twitter:image" content="https://www.lariatco.com/scheduling-image.png" /> */}
      </Helmet>

      <Header />
      <PageContainer>
      <ContactContainer>
      <ContactInfo>
        <Title>Contact Information</Title>
        <Description>
          <Paragraph><strong>Contact us right away!</strong></Paragraph>
          <Paragraph><strong>Phone:</strong> <Phone href="tel:+13177856040">(317) 785-6040</Phone></Paragraph>
          <Paragraph><strong>Email:</strong> <Email href="mailto:contact@lariatco.com">contact@lariatco.com</Email></Paragraph>
        </Description>
        </ContactInfo>
      </ContactContainer>
        <ContentContainer>
        {/* Quick Intro Call */}
          <InfoContainer>
            <Title>20-Minute Intro Call</Title>
            <Description>
            <Paragraph>Looking to learn more about LariatCo? Our 20-minute introductory call is perfect for those exploring our approach, understanding our services, 
              and seeing if we're a good fit for your business.</Paragraph>
              <br />
              <Paragraph>✅ Meet our team & learn about our process</Paragraph>
              <Paragraph>✅ Discuss your business needs at a high level</Paragraph>
              <Paragraph>✅ No pressure, just an introduction</Paragraph>
            </Description>
            <Button onClick={() => (window.location.href = "/scheduling/intro")}>Book Intro Call</Button>
          </InfoContainer>

          {/* Deep-Dive Discovery Call */}
          <InfoContainer>
            <Title>60-Minute Discovery Call</Title>
            <Description>
            <Paragraph>Ready to take action? Our 60-minute discovery session is for businesses serious about exploring customized solutions. This detailed call dives into 
              your pain points, goals, and strategy to create a tailored plan.</Paragraph>
              <br />
              <Paragraph>✅ Detailed business needs analysis</Paragraph>
              <Paragraph>✅ Custom solution recommendations</Paragraph>
              <Paragraph> ✅ Next steps for implementation</Paragraph>
            </Description>
            <Button onClick={() => (window.location.href = "/scheduling/discovery")}>Book Discovery Call</Button>
          </InfoContainer>
        </ContentContainer>
      </PageContainer>

      <Footer />
    </AnimationRevealPage>
  );
};
