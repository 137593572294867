import React from "react";
import { useLocation } from "react-router-dom";
import tw from "twin.macro";
import styled from "styled-components";

const Container = tw.div`relative mt-16 w-full`;
const Content = tw.div`max-w-screen-xl mx-auto pt-5 pb-20 lg:pt-5 pb-20`;
const TwoColumn = tw.div`flex flex-col sm:flex-row justify-between mt-6`;
const Column = tw.div`sm:w-6/12 sm:px-4 sm:my-2 flex flex-col mr-6`;
const Divider = tw.div`border-l border-marketing-secslate mx-4`;

const FormContainer = styled.div`
  ${tw`p-8 sm:p-10 md:p-12 bg-marketing-secseafoam text-marketing-secslate rounded-lg relative`}
  h2 {
    ${tw`text-3xl text-center sm:text-4xl font-bold`}
  }
  h3 {
    ${tw`text-2xl text-center sm:text-2xl font-bold mb-4 `}
  }
  h4 {
    ${tw`text-xl mt-2`}
  }
`;

const ServiceList = tw.ul``;
const ServiceItem = tw.li`mt-2 text-lg text-marketing-pricobolt hover:underline`;
const ScheduleIframe = tw.div`mt-2 w-full h-[600px]`; 
const Heading = tw.h3`mt-4`;
const Paragraph = tw.p``;
const Phone = tw.a`text-marketing-pricobolt hover:underline`;

const ImmediateResults = () => {
  const location = useLocation();
  const selectedCategories = location.state?.selectedCategories || [];

  // Extract category names from selections
  const categoryNames = selectedCategories.map(option => option.category);

  // Define services for each category with guiding text
  const services = [];

  if (categoryNames.includes("strategic")) {
    services.push({
      title: "Strategic Focus: Building the Right Plan",
      description: "For long-term success, it's crucial to establish a clear strategy before implementing solutions.",
      items: [
        { name: "Business Assessment", link: "/business-assessment" },
        { name: "Business Intelligence", link: "/business-intelligence" },
        { name: "Fractional Leadership", link: "/fractional-leadership" },
        { name: "Technology Architecture", link: "/technology-architecture" },
      ],
    });
  }


  if (categoryNames.includes("execution")) {
    services.push({
      title: "Execution Focus: Implementing the Right Solutions",
      description: "You're ready to act. These solutions help integrate, optimize, and secure your technology.",
      items: [
        { name: "System Integration", link: "/system-integration" },
        { name: "Business Intelligence", link: "/business-intelligence" },
        { name: "Technology Architecture", link: "/technology-architecture" },
        { name: "Data Infrastructure", link: "/data-infrastructure" },
      ],
    });
  }

  

  return (
    <Container>
      <Content>
        <FormContainer>
          <h2>Act Fast: Your Best-Fit Solution</h2>
          <TwoColumn>
            {/* Left Column - Service Recommendations & CTA */}
            <Column>
              <Heading>Connect With Us Immediately</Heading>
              <Paragraph>
                Have questions? Our team is ready to help.<br />
                Call us at{" "}
                <Phone href="tel:+1234567890">(317) 785-6040</Phone>{" "}
                or schedule a time below.
              </Paragraph>
              <br />
              <Paragraph>
                <strong>What to expect:</strong> In just 20 minutes, we'll connect, explore your ideas, and if you're ready, outline the next steps.
              </Paragraph>
              <br />

              {/* Service Recommendations */}
              {services.length > 0 && (
                <>
                  <Heading>Potential Solution Exploration</Heading>
                  {services.map((serviceCategory, index) => (
                    <div key={index}>
                      <Paragraph>{serviceCategory.description}</Paragraph>
                      <ServiceList>
                        {serviceCategory.items.map((service, i) => (
                          <ServiceItem key={i}>
                            <a href={service.link}>{service.name}</a>
                          </ServiceItem>
                        ))}
                      </ServiceList>
                      <br />
                    </div>
                  ))}
                </>
              )}
            </Column>

            <Divider />

            {/* Right Column - Booking Calendar */}
            <Column>
              <h3>Schedule Your Call</h3>
              <ScheduleIframe>
                <iframe
                  src="https://calendly.com/brian-lariatco/20-min-intro-call?hide_event_type_details=1&hide_gdpr_banner=1"
                  width="100%"
                  height="100%"
                  frameBorder="0"
                  allowFullScreen
                  title="Booking Appointment"
                ></iframe>
              </ScheduleIframe>
            </Column>
          </TwoColumn>
        </FormContainer>
      </Content>
  </Container>
);
}

export default ImmediateResults;
