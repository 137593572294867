// pages/Industries/IndustriesDetail.js

import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { useParams } from "react-router-dom";
// import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/MainHeader";
import IndustryHero from "components/hero/IndustryHero"
import IndustriesDetail from "components/features/IndustriesDetail"
import IndustryFAQ from "components/faqs/IndustryFAQ";
import ServiceOfferings from "components/cards/ServiceOfferings"
import SimpleContactUs from "components/forms/HomeContactUs";
import Footer from "components/footers/MainFooter";
import { Helmet } from "react-helmet-async";


export default () => {

  const { industryName } = useParams();

  return (
    <AnimationRevealPage disabled>
      <Helmet>
        <title>Industries We Serve | Empowering Your Business with LariatCo</title>
        {/* <meta name="description" content="Explore LariatCo's comprehensive services designed to make complex data and technology solutions accessible and manageable, empowering your business to thrive." />
        <link rel="canonical" href="https://www.lariatco.com/services" />
        <meta name="author" content="LariatCo" />

        <meta property="og:site_name" content="LariatCo" />
        <meta property="og:title" content="Our Services | Empowering Your Business with LariatCo" />
        <meta property="og:description" content="Discover how LariatCo's services simplify complex data and technology solutions, helping your business achieve growth and efficiency." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.lariatco.com/services" />
        <meta property="og:image" content="https://www.lariatco.com/twogcard.png" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Our Services | Empowering Your Business with LariatCo" />
        <meta name="twitter:description" content="Learn how LariatCo's comprehensive services make complex data and technology solutions accessible, enabling your business to thrive." />
        <meta name="twitter:image" content="https://www.lariatco.com/twogcard.png" /> */}
      </Helmet>
            <Header />
            <IndustryHero industryName={industryName} />
            <ServiceOfferings industryName={industryName} />
            <IndustriesDetail/>
            <IndustryFAQ industryName={industryName} />
            <SimpleContactUs />
            <Footer />
          </AnimationRevealPage>
        );
      };