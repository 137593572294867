import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import AboutHero from "components/hero/AboutHero";
import Header from "components/headers/MainHeader.js";
import Footer from "components/footers/MainFooter";
import AboutUsFeature from "components/features/AboutUsFeature"
import MainFeature1 from "components/features/TwoColWithButton.js";
import Features from "components/features/AboutUsValues";
import AboutUsOurWhy from "components/features/AboutUsOurWhy";
import GetStarted from "../components/cta/HomeGetStarted";
import SimpleContactUs from "../components/forms/HomeContactUs";
import visionImg from "../images/stock/aboutMission6.jpg"
import missionImg from "../images/stock/aboutVision6.jpg"
import MetaComponent from "components/common/meta";

const Subheading = tw.span`uppercase tracking-wider text-sm text-marketing-pricobolt`;

export default function AboutUs() {

  const meta = {
    title: "About Us | Empowering Businesses with Accessible Data and Technology Solutions | LariatCo",
    // description: "At LariatCo, we empower businesses to thrive by making complex data and technology solutions accessible and manageable.",
    // canonical: "https://www.lariatco.com/aboutus",
    // author: "LariatCo",
    // robots: "index, follow",
    // og: {
    //   site_name: "LariatCo",
    //   title: "About Us | Empowering Businesses with Accessible Data and Technology Solutions",
    //   description: "Discover how LariatCo makes complex data and technology solutions accessible, helping businesses manage operations efficiently and drive growth.",
    //   type: "website",
    //   url: "https://www.lariatco.com/aboutus",
    //   image: "https://www.lariatco.com/twogcard.png",
    // },
    // twitter: {
    //   card: "summary_large_image",
    //   title: "About Us | Empowering Businesses with Accessible Data and Technology Solutions",
    //   description: "At LariatCo, we empower businesses to thrive by making complex data and technology solutions accessible and manageable.",
    //   image: "https://www.lariatco.com/twogcard.png",
    // },
  };

  return (
    <AnimationRevealPage disabled>
      <MetaComponent meta={meta} />
      <Header />
      <AboutHero />
      <AboutUsFeature subheading="Who We Are" subheading2="Our Approach" subheading3="We make it simple: " />
      <MainFeature1
        subheading={<Subheading style={{ fontSize: "1.25rem" }}>Our Vision</Subheading>}
        heading="Harness Your Potential"
        description="Businesses often struggle with inefficiencies, fragmented systems, and operational challenges that hinder growth 
        and increase stress. Our vision is to help businesses transform their operations by delivering innovative, reliable solutions 
        that drive efficiency, security, and sustainable growth."
        buttonRounded={false}
        primaryButtonText="Contact Us"
        primaryButtonUrl="/contact"
        imageSrc={visionImg}
      />
      <MainFeature1
        subheading={<Subheading style={{ fontSize: "1.25rem" }}>Our Mission</Subheading>}
        heading="Maximize your business potential."
        description="Our mission is to empower businesses to thrive by making complex technology solutions accessible and manageable.  
        We offer tailored solutions that provide strategic and streamlined processes, integrated systems, and a transformative business, helping 
        you be confident as you grow and innovate."
        buttonRounded={false}
        primaryButtonText="Lets Chat"
        primaryButtonUrl="/scheduling"
        imageSrc={missionImg}
        textOnLeft={false}
      />
      <Features
        subheading={<Subheading style={{ fontSize: "1.25rem" }}>Our Values</Subheading>}
      />
      <AboutUsOurWhy />
      <GetStarted />
      <SimpleContactUs />
      
      <Footer />
    </AnimationRevealPage>
  );
};
