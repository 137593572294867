import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import tw from "twin.macro";
import styled from "styled-components";
import { ReactComponent as ArrowRightIcon } from "images/arrow-right-icon.svg";

const Container = tw.div`relative mt-16 w-full`;
const Content = tw.div`max-w-screen-xl mx-auto pt-5 pb-20 lg:pt-5 pb-20`;
const TwoColumn = tw.div`flex flex-col sm:flex-row justify-between mt-6`;
const Column = tw.div`sm:w-6/12 sm:px-6 sm:my-2 flex flex-col mr-6`;
const Divider = tw.div`border-l border-marketing-secslate mx-4`;
const ArrowRight = styled(ArrowRightIcon)`
  ${tw`inline-flex ml-2 w-4 text-marketing-secslate`}
`;

const FormContainer = styled.div`
  ${tw`p-8 sm:p-10 md:p-12 bg-marketing-secseafoam text-marketing-secslate rounded-lg relative`};
  h2 {
    ${tw`text-3xl text-center sm:text-4xl font-bold`};
  }
  h3 {
    ${tw`text-2xl text-center sm:text-2xl font-bold mb-4 `};
  }
`;

const Paragraph = tw.p`text-base`;
const ServiceList = tw.ul`mt-4`;
const ServiceItem = tw.h5`mt-2 text-lg text-marketing-secslate`;
const Link = tw.a`inline-block text-marketing-pricobolt font-bold cursor-pointer transition duration-300 hover:underline `;
const Span = tw.a`mt-auto inline-flex items-center pb-4 lg:pt-5 text-lg text-marketing-pricobolt leading-none hocus:text-marketing-secslate transition duration-300`;
const LinkList = tw.ul`mt-6`;
const LinkItem = tw.li`mt-2 text-lg font-bold text-marketing-secslate `;
const InputContainer = tw.div`relative py-2 mt-6`;
const Label = tw.label`absolute top-0 left-0 tracking-wide text-marketing-secslate font-semibold text-lg`;
const Input = tw.input`w-full bg-transparent text-white text-base text-sm font-medium tracking-wide border-b-2 pt-6 pb-2 hocus:border-marketing-secslate 
focus:outline-none transition duration-200`;
const SubmitButton = tw.button`w-full sm:w-32 mt-6 py-3 bg-marketing-secslate text-white rounded-full font-bold tracking-wide shadow-lg uppercase text-sm 
transition duration-300 transform focus:outline-none focus:shadow-outline hover:bg-marketing-pricobolt hover:text-white hocus:-translate-y-px hocus:shadow-xl`;


const ExploringResultsPage = () => {
  const location = useLocation();
  const selectedCategories = location.state?.selectedCategories || [];
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
      selectedOptions: [],
      name: '',
      email: '',
      phone: ''
    });

  // Extract category names from selected options
  const categoryNames = selectedCategories.map((option) => option.category);

  // Define service recommendations dynamically
  let primaryRecommendation = null;
  const additionalServices = [];

  if (categoryNames.includes("strategic")) {
    primaryRecommendation = {
      name: "Business Assessment",
      link: "/business-assessment",
      description:
        "If you're exploring solutions, a Business Assessment can help you understand your current technology landscape. It provides insights into areas of improvement, helping you make informed decisions when the time is right.",
    };
    additionalServices.push(
      { name: "Business Intelligence", link: "/business-intelligence" },
      { name: "Fractional CTO", link: "/fractional-leadership" },
      { name: "Technology Architecture", link: "/technology-architecture" }
    );
  }

  if (categoryNames.includes("execution")) {
    if (!primaryRecommendation) {
      primaryRecommendation = {
        name: "Technology Architecture",
        link: "/technology-architecture",
        description:
          "If you're considering future upgrades, understanding your Technology Architecture is key. It lays the groundwork for scalability, security, and efficiency—ensuring that when you're ready, your systems will support your growth.",
      };
    }
    additionalServices.push(
      { name: "System Integration", link: "/system-integration" },
      { name: "Business Intelligence", link: "/business-intelligence" },
      { name: "Data Infrastructure", link: "/data-infrastructure" }
    );
  }

  // Remove duplicate additional services
  const uniqueAdditionalServices = [
    ...new Map(additionalServices.map((s) => [s.name, s])).values(),
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
  
    const payload = {
      name: formData.name,
      email: formData.email,
      phone: formData.phone,
    };
  
    try {
      const response = await fetch("/api/sendMessage", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      });
  
      if (response.ok) {
        alert("Your information has been sent successfully!");

        const link = document.createElement("a");
        link.href = "/techAuditInfographic.pdf"; // Ensure this file is in `public/`
        link.download = "techAuditInfographic.pdf";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
  
      } else {
        const error = await response.json();
        alert(error.error || "Failed to send your information. Please try again.");
        console.error("Failed to send email:", error.error);
      }
    } catch (err) {
      alert("A network error occurred. Please check your connection and try again.");
      console.error("Error submitting form:", err);
    } finally {
      setLoading(false);
    }
  };
  

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };
  
  return (
    <Container>
      <Content>
        <FormContainer>
          <h2>Exploring Your Options? Here's Where to Start</h2>
          <TwoColumn>
            <Column>
              <h3>Insights & Recommendations</h3>
              <Paragraph>
                We know that making technology decisions takes time. If you're researching, we encourage you to explore available solutions and learn more before taking action. 
                Feel free to reach out anytime via phone{" "}
                <Link href="tel:+1234567890">(317) 785-6040</Link> or email{" "}
                <Link href="mailto:contact@lariatco.com">contact@lariatco.com</Link>.
              </Paragraph>

              {/* ✅ Primary Recommendation */}
              {primaryRecommendation && (
                <ServiceList>
                  <ServiceItem>
                    <strong>Primary Recommendation:</strong> <Paragraph>Consider exploring{" "}
                    <Link href={primaryRecommendation.link}>
                      {primaryRecommendation.name}
                    </Link>
                    . {primaryRecommendation.description}</Paragraph>
                  </ServiceItem>
                </ServiceList>
              )}

              {/* ✅ Additional Services */}
              {uniqueAdditionalServices.length > 0 && (
                <>
                  <ServiceItem>
                    <strong>Other Areas to Explore:</strong> <Paragraph>These services might align with your long-term goals.</Paragraph>
                  </ServiceItem>
                  <ServiceList>
                    {uniqueAdditionalServices.map((service, index) => (
                      <ServiceItem key={index}>
                        <Link href={service.link}>{service.name}</Link>
                      </ServiceItem>
                    ))}
                  </ServiceList>
                </>
              )}

              {/* ✅ Keep existing bottom links untouched */}
              <LinkList>
                <LinkItem>
                  Explore Case Studies:{" "}
                  <Link href="/case-studies">
                    <Span>See real-world success stories <ArrowRight /></Span>
                  </Link>
                </LinkItem>
                <LinkItem>
                  Read Our Blog:{" "}
                  <Link href="/blog">
                    <Span>Learn industry best practices <ArrowRight /></Span>
                  </Link>
                </LinkItem>
                <LinkItem>
                  View Full Services:{" "}
                  <Link href="/business-assessment">
                    <Span>Understand our offerings <ArrowRight /></Span>
                  </Link>
                </LinkItem>
              </LinkList>
            </Column>

            <Divider />

            {/* ✅ Right Column - Keep the form untouched */}
            <Column>
              <h3>Get Your Free Tech Audit Guide</h3>
              <Paragraph>
                Fill out the form below to receive an exclusive infographic explaining the basic steps to a technology audit. Gain insights into how businesses prepare for
                long-term success.
              </Paragraph>

              <form >
                <InputContainer>
                  <Label htmlFor="name">Your Full Name</Label>
                  <Input 
                    id="name" 
                    name="name" 
                    type="text" 
                    placeholder="E.g. John Doe" 
                    value={formData.name}
                    onChange={handleInputChange}
                    required
                  />
                </InputContainer>
                <InputContainer>
                  <Label htmlFor="email">Your Business Email</Label>
                  <Input 
                    id="email" 
                    name="email" 
                    type="email" 
                    placeholder="E.g. john@mail.com" 
                    value={formData.email}
                    onChange={handleInputChange}
                    required
                  />
                </InputContainer>
                <InputContainer>
                  <Label htmlFor="phone">Your Phone Number</Label>
                  <Input 
                    id="phone" 
                    name="phone" 
                    type="text" 
                    placeholder="E.g. (123) 456-7890" 
                    value={formData.phone}
                    onChange={handleInputChange}
                    required
                  />
                </InputContainer>
                <SubmitButton type="submit" disabled={loading} onClick={handleSubmit}>
                  {loading ? "Sending..." : "See Results"}
                </SubmitButton>
                  </form>
            </Column>
          </TwoColumn>
        </FormContainer>
      </Content>
    </Container>
  );
};

export default ExploringResultsPage;
