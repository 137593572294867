import React from "react";
import styled from "styled-components";
import tw from "twin.macro";

const Section = tw.div`my-24 text-center flex justify-center px-4 md:px-0`;
const BackgroundWrapper = tw.div`bg-marketing-secseafoam px-6 py-8 rounded-lg shadow-lg w-full max-w-6xl`;
const Title = tw.h2`text-2xl md:text-3xl font-bold text-marketing-pricobolt`;
const Subtitle = tw.p`text-base md:text-lg text-marketing-secslate mt-2 max-w-2xl mx-auto`;
const GridContainer = tw.div`mt-8 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6`;
const CTAButton = tw.a`mt-6 px-8 py-4 text-lg font-bold text-white bg-marketing-pricobolt rounded-lg transition duration-300 hover:bg-marketing-secslate inline-block`;

const Card = styled.a`
  ${tw`w-full p-6 bg-gray-100 rounded-lg shadow-md text-center hover:shadow-lg transition duration-300 cursor-pointer`}
  text-decoration: none;
  &:hover {
    transform: translateY(-5px);
  }
`;

const Icon = tw.div`text-primary-500 text-3xl md:text-4xl mb-3`;
const ServiceTitle = tw.h4`font-bold text-lg text-gray-900`;
const Description = tw.p`text-sm text-gray-700 mt-2`;

const industryServices = {
  manufacturing: [
    { title: "Proactive Digital Maintenance", icon: "🔧", desc: "Ensure continuous operations with predictive and proactive digital maintenance solutions that integrate with existing infrastructure.", link: "/system-integration" },
    { title: "Smart Process Automation", icon: "⚙️", desc: "Streamline manufacturing workflows with integrated automation solutions that improve efficiency and reduce manual dependencies.", link: "/system-integration" },
    { title: "Manufacturing Business Intelligence", icon: "📊", desc: "Gain actionable insights with real-time data analytics and business intelligence tailored for manufacturing operations.", link: "/business-intelligence" }
  ],
  construction: [
    { title: "Construction Project Management Software", icon: "📋", desc: "Increase project efficiency with digital tools that automate scheduling, collaboration, and tracking.", link: "/technology-architecture" },
    { title: "IoT-Enabled Smart Building Solutions", icon: "🏗️", desc: "Leverage IoT sensors and automation to enhance energy efficiency, security, and operational oversight.", link: "/technology-architecture" },
    { title: "AI-Powered Construction Cost Estimation", icon: "📊", desc: "Improve accuracy and reduce budget overruns with AI-driven cost forecasting tools.", link: "/business-intelligence" }
  ],
  logistics: [
    { title: "Fleet Tracking & Logistics", icon: "🚚", desc: "Optimize routes, monitor fleet health, and reduce fuel costs with automated logistics management.", link: "/business-intelligence" },
    { title: "Real-Time Supply Chain Visibility", icon: "🔎", desc: "Enhance transparency and efficiency with advanced tracking for every stage of your supply chain.", link: "/business-intelligence" },
    { title: "Robotic & AI Warehouse Automation", icon: "🏭", desc: "Increase efficiency and reduce labor costs with autonomous robotics and AI-driven warehouse management systems.", link: "/technology-architecture" }
  ],
  businessservices: [
    { title: "Business Analytics & Intelligence", icon: "📊", desc: "Make smarter business decisions with powerful data insights and automated reporting.", link: "/business-intelligence" },
    { title: "Advanced Compliance & Cybersecurity Solutions", icon: "🔐", desc: "Protect sensitive data and ensure regulatory compliance with top-tier security solutions.", link: "/cyber-security" },
    { title: "Scalable Cloud Infrastructure", icon: "☁️", desc: "Enable seamless collaboration with secure, scalable cloud computing and storage.", link: "/technology-architecture" }
  ]
};

export default function ServiceOfferings({ industryName }) {
  const services = industryServices[industryName] || [];

  if (services.length === 0) return null;

  return (
    <Section>
      <BackgroundWrapper id="solutions">
        <Title>Service Offerings</Title>
        <Subtitle>Our industry-specific solutions are designed to optimize efficiency, security, and growth.</Subtitle>

        <GridContainer>
          {services.map((service, i) => (
            <Card key={i} href={service.link}>
              <Icon>{service.icon}</Icon>
              <ServiceTitle>{service.title}</ServiceTitle>
              <Description>{service.desc}</Description>
            </Card>
          ))}
        </GridContainer>
        <CTAButton href="/services">View All Services</CTAButton>
      </BackgroundWrapper>
    </Section>
  );
}
