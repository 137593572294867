import React, { useState } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components/macro"; //eslint-disable-line
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { SectionHeading } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { ReactComponent as ChevronDownIcon } from "feather-icons/dist/icons/chevron-down.svg";

const PrimaryBackgroundContainer = tw(Container)`-mx-8 px-8 bg-marketing-secseafoam text-gray-100`;

const HeadingContainer = tw.div``;
const Heading = tw(SectionHeading)`text-marketing-secslate`;
const Description = tw(SectionDescription)`mx-auto text-center text-marketing-secslate`;

const FaqsContainer = tw.div`mt-10 sm:mt-16 w-full flex-1 max-w-screen-lg mx-auto`;
const Faq = tw.div`select-none cursor-pointer border-b-2 border-primary-300 hover:border-primary-500 transition-colors duration-300 py-6`;
const Question = tw.div`flex justify-between items-center`;
const QuestionText = tw.div`text-sm sm:text-lg text-marketing-pricobolt font-semibold tracking-wide`;
const QuestionToggleIcon = styled(motion.span)`
  ${tw`ml-2 transition text-marketing-secslate duration-300`}
  svg {
    ${tw`w-6 h-6`}
  }
`;
const Answer = tw(motion.div)`hidden text-[16px] font-normal mt-4 text-marketing-secslate`;

const industryFAQs = {
  manufacturing: [
    { question: "What is smart process automation in manufacturing?", answer: "Smart process automation integrates AI-driven workflows, automated production scheduling, and intelligent quality control to streamline operations and reduce downtime." },
    { question: "How does business intelligence impact manufacturing operations?", answer: "Business intelligence enables real-time insights into production efficiency, machine health, and supply chain performance, allowing manufacturers to make proactive decisions that improve output." },
    { question: "What are the biggest cybersecurity risks for manufacturers?", answer: "Manufacturers face ransomware attacks, intellectual property theft, and legacy security vulnerabilities. Implementing our solutions helps safeguard critical business data." },
    { question: "How does system integration improve manufacturing efficiency?", answer: "Seamless system integration eliminates data silos, optimizes workflows, and enhances operational control." }
  ],
  construction: [
    { question: "How can AI-powered cost estimation improve project budgeting?", answer: "AI cost estimation tools analyze past project data, material costs, and labor rates to create accurate forecasts, preventing budget overruns." },
    { question: "What are the benefits of cloud-based project management in construction?", answer: "Cloud-based platforms enable real-time collaboration, automate scheduling, and reduce miscommunication errors, improving efficiency and shortening project timelines." },
    { question: "How does IoT improve construction site efficiency?", answer: "IoT-enabled devices monitor equipment health, track material usage, and enhance safety compliance. Implementing solutions like this optimizes resources and prevents project delays." },
    { question: "Which industries face similar operational challenges as construction?", answer: "Industries like manufacturing and logistics also require automated workflows, real-time data tracking, and compliance-driven solutions." }
  ],
};

export default function IndustryFAQ({ industryName }) {
  const faqs = industryFAQs[industryName] || [];
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);

  const toggleQuestion = questionIndex => {
    setActiveQuestionIndex(activeQuestionIndex === questionIndex ? null : questionIndex);
  };

  return (
    <PrimaryBackgroundContainer>
      <ContentWithPaddingXl>
        <HeadingContainer>
          <Heading>Frequently Asked Questions</Heading>
          <Description>Answers to common questions about our industry-specific solutions.</Description>
        </HeadingContainer>
        <FaqsContainer>
          {faqs.map((faq, index) => (
            <Faq key={index} onClick={() => toggleQuestion(index)}>
              <Question>
                <QuestionText>{faq.question}</QuestionText>
                <QuestionToggleIcon
                  variants={{
                    collapsed: { rotate: 0 },
                    open: { rotate: -180 }
                  }}
                  initial="collapsed"
                  animate={activeQuestionIndex === index ? "open" : "collapsed"}
                  transition={{ duration: 0.2 }}
                >
                  <ChevronDownIcon />
                </QuestionToggleIcon>
              </Question>
              <Answer
                variants={{
                  open: { opacity: 1, height: "auto", marginTop: "16px", display: "block" },
                  collapsed: { opacity: 0, height: 0, marginTop: "0px", display: "none" }
                }}
                initial="collapsed"
                animate={activeQuestionIndex === index ? "open" : "collapsed"}
                transition={{ duration: 0.3 }}
              >
                {faq.answer}
              </Answer>
            </Faq>
          ))}
        </FaqsContainer>
      </ContentWithPaddingXl>
    </PrimaryBackgroundContainer>
  );
}
