import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import {css} from "styled-components/macro"; //eslint-disable-line
import { SectionHeading as HeadingTitle, Subheading } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { ReactComponent as TagIcon } from "feather-icons/dist/icons/tag.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-1.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "../../images/svg-decorator-blob-3.svg";
import blogImage1 from "../../images/stock/blogs/blog1.jpg"
import blogImage2 from "../../images/stock/blogs/blog2.jpg"
import blogImage3 from "../../images/stock/blogs/blog3.jpg"
import blogImage4 from "../../images/stock/blogs/blog4.jpg"
import blogImage5 from "../../images/stock/blogs/blog5.jpg"
import blogImage6 from "../../images/stock/blogs/blog6.jpg"
import blogImage7 from "../../images/stock/blogs/blog7.jpg"
import blogImage8 from "../../images/stock/blogs/blog8.jpg"

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;
const ThreeColumn = tw.div`flex flex-col items-center lg:items-stretch lg:flex-row flex-wrap`;
const Column = tw.div`mt-24 lg:w-1/3`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-xl text-marketing-secslate text-center max-w-sm`;

const Card = tw.div`lg:mx-4 xl:mx-8 max-w-sm flex flex-col h-full`;
const ImageWrapper = tw.div`w-full h-80 lg:h-64 overflow-hidden rounded-t`;

const StyledImage = styled.img`
  ${tw`w-full h-full object-cover`}
`;

const Details = tw.div`p-6 rounded border-2 border-t-0 rounded-t-none border-dashed border-marketing-pricobolt flex-1 flex flex-col items-center text-center lg:block lg:text-left`;
const MetaContainer = tw.div`flex items-center`;
const Meta = styled.div`
  ${tw`text-secondary-100 font-medium text-sm flex items-center leading-none mr-6 last:mr-0`}
  svg {
    ${tw`w-4 h-4 mr-1`}
  }
`;

const Title = tw.h5`mt-4 leading-snug font-bold text-marketing-pricobolt text-lg`;
const Description = tw.p`mt-2 text-sm text-marketing-secslate`;
const Category = tw.p`mt-2 text-sm text-marketing-pricobolt`;
const Link = styled(PrimaryButtonBase).attrs({as: "a"})`
  ${tw`inline-block mt-4 text-sm bg-marketing-secslate text-white hover:bg-marketing-pricobolt font-semibold`}
`

const DecoratorBlob1 = tw(
  SvgDecoratorBlob1
)`-z-10 absolute bottom-0 right-0 w-48 h-48 transform translate-x-40 -translate-y-8 opacity-25`;
const DecoratorBlob2 = tw(
  SvgDecoratorBlob2
)`-z-10 absolute top-0 left-0 w-48 h-48 transform -translate-x-32 translate-y-full opacity-25`;

export default ({
  subheading = "",
  heading = "",
  description = "Some of our more recent blogs",

}) => {
  const blogPosts = [
    {
      imageSrc: blogImage1,
      category: "Data Infrastructure",
      title: "Building Data Infrastructure for Small Businesses on a Budget",
      description: "Practical tips and tools to help businesses like yours leverage affordable solutions for better decision-making, increased efficiency, and long-term growth.",
      url: "/blog/building-data-infrastructure-for-small-businesses-on-a-budget"
    },
    {
      imageSrc: blogImage2,
      category: "Business Intelligence",
      title: "The Intersection of Data Analytics and Business Intelligence",
      description: "Explore how businesses can transform overwhelming datasets into actionable strategies that enhance customer experiences, streamline operations, and boost profitability.",
      url: "/blog/the-intersection-of-data-analytics-and-business-intelligence"
    },
    {
      imageSrc: blogImage3,
      category: "Digital Transformation",
      title: "AI 101: Empowering Your Business for the Future",
      description: "Discover what AI is, how it works, and why understanding its fundamentals is essential for every employee, leader, and manager in today's rapidly evolving business landscape.",
      url: "/blog/ai-101-empowering-your-business-for-the-future"
    },
    {
      imageSrc: blogImage4,
      category: "Digital Transformation",
      title: "How to Conduct a Technology Audit: A Step-by-Step Guide for SMB Leaders",
      description: "A brief guide that shows leaders how to perform a technology audit on their business.",
      url: "/blog/how-to-conduct-a-technology-audit-for-smb-leaders"
    },
    {
      imageSrc: blogImage5,
      category: "Business Assessment",
      title: "7 Hidden Costs of Operational Inefficiency (and How to Fix Them)",
      description: "Uncover the unexpected expenses dragging your business down and discover actionable solutions to save time, money, and energy.",
      url: "/blog/7-hidden-costs-of-operational-inefficiency"
    },
    {
      imageSrc: blogImage6,
      category: "Digital Transformation",
      title: "The Midwest Manufacturing Playbook: Scaling with Digital Transformation",
      description: "How Midwest manufacturers can modernize operations, improve efficiency, and stay competitive.",
      url: "/blog/midwest-manufacturing-playbook-scaling-with-digital-transformation"
    },
    {
      imageSrc: blogImage7,
      category: "Business Intelligence",
      title: "Why Midwest Logistics Companies Must Embrace Business Intelligence to Stay Competitive",
      description: "Unlocking Efficiency, Cost Savings, and Real-Time Insights in Supply Chain Management.",
      url: "/blog/why-midwest-logistics-companies-must-embrace-business-intelligence"
    },
    {
      imageSrc: blogImage8,
      category: "Technology Consulting",
      title: "Building Smarter: How Midwest Construction Firms Are Leveraging Technology for Efficiency",
      description: "How Digital Tools, Cloud Solutions, and Automation Are Reshaping Construction Across the Midwest",
      url: "/blog/building-smarter-midwest-construction-firms-and-technology"
    }
];

  return (
    <Container>
      <Content>
        <HeadingInfoContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          <HeadingTitle>{heading}</HeadingTitle>
          <HeadingDescription>{description}</HeadingDescription>
        </HeadingInfoContainer>
        <ThreeColumn>
          {blogPosts.map((post, index) => (
            <Column key={index}>
              <Card>
                <ImageWrapper>
                  <StyledImage
                    src={post.imageSrc}
                    alt={post.title}
                    loading="lazy"
                    width="400"
                    height="256"
                    fetchpriority={index === 0 ? "high" : "low"}
                  />
                </ImageWrapper>
                <Details>
                  <MetaContainer>
                    <Meta>
                      <TagIcon />
                      <Category>{post.category}</Category>
                    </Meta>
                  </MetaContainer>
                  <Title>{post.title}</Title>
                  <Description >{post.description}</Description>
                  <Link href={post.url}>Read Post</Link>
                </Details>
              </Card>
            </Column>
          ))}
        </ThreeColumn>
      </Content>
      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};
