import React from "react";
import tw from "twin.macro";
import styled from "styled-components";

const Container = tw.div`
  relative -mx-8 mt-8 mb-0 h-[70vh] sm:h-[60vh] md:h-[60vh] lg:h-[90vh] min-h-[50vh] h-screen 
  flex flex-col justify-center items-center text-center overflow-hidden
`;

const BackgroundImage = styled.img`
  ${tw`absolute inset-0 w-full h-full object-cover -z-10`}
  filter: brightness(20%);
`;

const HeroContent = tw.div`relative max-w-4xl px-6 z-10`;
const Title = tw.h1`text-4xl lg:text-5xl font-bold leading-tight text-marketing-secseafoam`;
const Subheading = tw.h2`mt-4 py-4 text-lg lg:text-xl max-w-3xl text-white`;
const Paragraph = tw.p`text-white m-4`;
const CTAButton = tw.a`
  inline-block mt-6 px-8 py-4 text-lg font-bold text-white 
  bg-marketing-secseafoam rounded-lg 
  transition duration-300 hover:bg-marketing-pricobolt
`;

const industries = {
  manufacturing: {
    title: "Manufacturing",
    subheading: "Optimize Operations & Drive Growth",
    description:
      "From automation to real-time production insights, we help manufacturing businesses scale with confidence.",
    imageSrc: "/industries/industriesManufacturing.jpg",
  },
  construction: {
    title: "Construction",
    subheading: "Building Smarter, More Efficiently",
    description:
      "We streamline project management, enhance collaboration, and improve workflow efficiency in construction businesses.",
    imageSrc: "/industries/industriesConstruction.jpg",
  },
  logistics: {
    title: "Logistics & Supply Chain",
    subheading: "Efficiency, Optimization, and Cost Reduction",
    description:
      "Optimize fleet tracking, enhance supply chain visibility, and reduce costs through smart logistics solutions.",
    imageSrc: "/industries/industriesLogistics.jpg",
  },
  businessservices: {
    title: "Business Services",
    subheading: "Technology Driven Solutions for Modern Businesses",
    description:
      "Leverage cloud collaboration, AI-powered analytics, and cybersecurity to scale your business securely.",
    imageSrc: "/industries/industriesBusiness.jpg",
  },
};

export default function IndustryHero({ industryName }) {
  const industry = industries[industryName];

  const fallback = {
    title: "Industry Not Found",
    subheading: "Please check the URL or select an industry from our industries page.",
    imageSrc: "/default-hero.jpg",
  };

  const current = industry || fallback;

  return (
    <Container aria-label={`Background image for ${current.title}`}>
      <BackgroundImage src={current.imageSrc} alt={current.title} fetchpriority="high" />
      <HeroContent>
        <Subheading>{current.subheading}</Subheading>
        <Title>
          {current.title}
          {industry && (
            <>
              {" "}
              Technology Solutions
              <br />
              Automate. Optimize. Scale.
            </>
          )}
        </Title>
        {industry && <Paragraph>{current.description}</Paragraph>}
        {industry && <CTAButton href="#solutions">Explore Solutions</CTAButton>}
      </HeroContent>
    </Container>
  );
}
