import React from "react";
import tw from "twin.macro";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import SimpleContactUs from "components/forms/SolutionsContactUs";
import MainFeature1 from "components/features/SolutionsMainFeature";
import Header from "components/headers/MainHeader.js";
import SubHeader from "components/headers/SolutionsSubHeader";
import Footer from "components/footers/MainFooter";
import { PiDesktopTower, PiWrench, PiCode, PiMonitor } from "react-icons/pi";
import { Helmet } from "react-helmet-async";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto pt-6 pb-20 md:py-6 md:pb-20 items-start`;

export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-marketing-pricobolt`;
  const HighlightedText = tw.span`text-marketing-secslate`;

  const page1Icons = [
    {
      heading: "Tailored Applications",
      icon: <PiCode />, 
      description: "Custom-designed solutions for your specific requirements.",
    },
    {
      heading: "Optimized Tech Stacks",
      icon: <PiWrench />, 
      description: "Streamlined platforms to maximize performance.",
    },
    {
      heading: "End-to-End Workspace Solutions",
      icon: <PiMonitor/>, 
      description: "Fully managed systems for seamless operations.",
    },
    {
      heading: "Automated Workflows",
      icon: <PiDesktopTower />, 
      description: "Enhanced productivity with integrated automation and support.",
    },
  ];

  const bottomDescripton = "Build a technology foundation that's as adaptable and forward-thinking as your business."

  return (
    <AnimationRevealPage disabled>
      <Helmet>
        {/* <title>Technology Architecture | Build a Scalable Future with LariatCo</title>
        <meta name="description" content="Optimize your business with LariatCo’s technology architecture solutions. Custom applications, cloud-based infrastructure, and fully managed workspaces designed for efficiency and scalability." />
        <link rel="canonical" href="https://www.lariatco.com/services/technology-architecture" />
        <meta name="author" content="LariatCo" />

        <meta property="og:site_name" content="LariatCo" />
        <meta property="og:title" content="Technology Architecture | Build a Scalable Future with LariatCo" />
        <meta property="og:description" content="Transform your technology infrastructure with LariatCo’s tailored solutions. Optimize your tech stack, integrate cloud services, and automate workflows for seamless operations." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.lariatco.com/services/technology-architecture" />
        <meta property="og:image" content="https://www.lariatco.com/twogcard.png" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Technology Architecture | Build a Scalable Future with LariatCo" />
        <meta name="twitter:description" content="Leverage LariatCo’s technology architecture services to create a modern, scalable, and efficient business foundation with cloud solutions, automation, and managed workspaces." />
        <meta name="twitter:image" content="https://www.lariatco.com/twogcard.png" /> */}
      </Helmet>
        <Header />
        <Container>
        <SubHeader />
          <TwoColumn>
          
          <MainFeature1
          subheading={<Subheading>Technology Architecture</Subheading>}
          heading={
            <>
            <HighlightedText>Custom Solutions  <span style={{ color: "#0000D3"}}>Designed for Growth</span></HighlightedText>
            </>
          }
          description="Elevate your business with technology architecture built to meet your unique needs. Whether it's creating tailored 
          applications, optimizing tech stacks, integrating cloud and hardware systems, or managing employee workspaces, our comprehensive 
          approach drives efficiency, scalability, and success."
          icons={page1Icons}
          bottomDescription={bottomDescripton}
            />
            
              <SimpleContactUs />
            
          </TwoColumn>
        </Container>
        <Footer />
      </AnimationRevealPage>
    );
  };
