import React from "react";
import GlobalStyles from 'styles/GlobalStyles';
import { css } from "styled-components/macro"; //eslint-disable-line
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import {ThemeProvider, BaseStyles} from '@primer/react';
import { Analytics } from '@vercel/analytics/react';
import { SpeedInsights } from "@vercel/speed-insights/react"

import HomePage from "pages/HomePage";
/* Inner Pages */
import LoginPage from "pages/Login.js";
import SignupPage from "pages/Signup.js";
import AboutUsPage from "pages/AboutUs.js";
import ContactUsPage from "pages/ContactUs.js";
import Industries from "pages/Industries/Industries";
import Services from "pages/Services/Services.js";
import CaseStudies from "pages/CaseStudy/CaseStudies";
import CaseStudyDetails from "pages/CaseStudy/CaseStudyDetails";
import IndustriesDetail from "pages/Industries/IndustriesDetail"
import ImmediateResultsPage from "pages/Services/ImmediateResultsPage";
import SixMonthsResultsPage from "pages/Services/SixMonthResultsPage";
import ExploringResults from "pages/Services/ExploringResultsPage"
import Scheduling from "pages/Scheduling/Scheduling";
import BusinessAssessment from "pages/Services/BusinessAssessment";
import TechnologyArchitecture from "pages/Services/TechnologyArchitecture";
import FractionalLeadership from "pages/Services/FractionalLeadership";
import DataTechnology from "pages/Services/DataInfrastructure";
import Integraton from "pages/Services/Integration";
import CyberSecurity from "pages/Services/CyberSecurity";
import BusinessIntelligence from "pages/Services/BusinesIntelligence";
import TermsOfService from "pages/TermsOfService";
import PrivacyPolicy from "pages/PrivacyPolicy";
import Intro from "pages/Scheduling/Intro";
import Discovery from "pages/Scheduling/Discovery";
import BlogPage from "pages/Blog/BlogPage";
import Blog1 from "pages/Blog/Blog1";
import Blog2 from "pages/Blog/Blog2";
import Blog3 from "pages/Blog/Blog3";
import Blog4 from "pages/Blog/Blog4";
import Blog5 from "pages/Blog/Blog5";
import Blog6 from "pages/Blog/Blog6";
import Blog7 from "pages/Blog/Blog7";
import Blog8 from "pages/Blog/Blog8";

// import Ai from "pages/Ai/Ai"
// import HIT from "pages/H.I.T.Page"


export default function App() {
  // If you want to disable the animation just use the disabled `prop` like below on your page's component
  // return <AnimationRevealPage disabled>xxxxxxxxxx</AnimationRevealPage>;
  
  return (
    <>
    <ThemeProvider>
      <BaseStyles>
      <GlobalStyles />
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/aboutus" element={<AboutUsPage />} />
          <Route path="/industries" element={<Industries />} />
          <Route path="/services" element={<Services />} />
          <Route path="/immediate-results" element={<ImmediateResultsPage />} />
          <Route path="/six-months-results" element={<SixMonthsResultsPage />} />
          <Route path="/exploring-results" element={<ExploringResults />} />
          <Route path="/business-assessment" element={<BusinessAssessment />} />
          <Route path="/system-integration" element={<Integraton />} />
          <Route path="/fractional-leadership" element={<FractionalLeadership />} />
          <Route path="/data-infrastructure" element={<DataTechnology />} />
          <Route path="/business-intelligence" element={<BusinessIntelligence />} />
          <Route path="/technology-architecture" element={<TechnologyArchitecture />} />
          <Route path="/cyber-security" element={<CyberSecurity />} />          
          <Route path="/contact" element={<ContactUsPage />} />
          <Route path="/terms-and-conditions" element={<TermsOfService />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/signup" element={<SignupPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/scheduling" element={<Scheduling />} />
          <Route path="/scheduling/intro" element={<Intro />} />
          <Route path="/scheduling/discovery" element={<Discovery />} />
          <Route path="/case-studies" element={<CaseStudies />} />
          <Route path="/case-studies/:id" element={<CaseStudyDetails />} />
          <Route path="/industries/:industryName" element={<IndustriesDetail />} />
          <Route path="/blog" element={<BlogPage />} />
          <Route path="/blog/building-data-infrastructure-for-small-businesses-on-a-budget" element={<Blog1 />} />
          <Route path="/blog/the-intersection-of-data-analytics-and-business-intelligence" element={<Blog2 />} />
          <Route path="/blog/ai-101-empowering-your-business-for-the-future" element={<Blog3 />} />
          <Route path="/blog/how-to-conduct-a-technology-audit-for-smb-leaders" element={<Blog4 />} />
          <Route path="/blog/7-hidden-costs-of-operational-inefficiency" element={<Blog5 />} />
          <Route path="/blog/midwest-manufacturing-playbook-scaling-with-digital-transformation" element={<Blog6 />} />
          <Route path="/blog/why-midwest-logistics-companies-must-embrace-business-intelligence" element={<Blog7 />} />
          <Route path="/blog/building-smarter-midwest-construction-firms-and-technology" element={<Blog8 />} />
        </Routes>
      </Router>
      <Analytics />
      <SpeedInsights />
      </BaseStyles>
      </ThemeProvider>
    </>
  );
}


