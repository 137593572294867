import React from "react";
import tw from "twin.macro";
// import styled from "styled-components";
import {Container as ContainerBase } from "components/misc/Layouts.js"
import logo from "../../images/logos/logo-white-transparent.png";
// import { ReactComponent as FacebookIcon } from "../../images/facebook-icon.svg";
// import { ReactComponent as TwitterIcon } from "../../images/twitter-icon.svg";
// import { ReactComponent as YoutubeIcon } from "../../images/youtube-icon.svg";

const Container = tw(ContainerBase)`bg-marketing-secslate text-gray-100 pt-12 -mx-8 -mb-8`
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const Row = tw.div`flex items-center justify-center flex-col px-8`

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-96`;

const LinksContainer = tw.div`mt-8 font-medium flex flex-wrap justify-center items-center flex-col sm:flex-row`
const Link = tw.a`border-b-2 border-transparent hocus:text-gray-300 hocus:border-gray-300 pb-1 transition duration-300 mt-2 mx-4`;
const LegalContainer = tw.div`mt-1 text-sm flex flex-wrap justify-center items-center flex-col sm:flex-row`
const LegalLink = tw.a`border-b-2 text-gray-600 border-transparent hocus:text-gray-300 hocus:border-gray-300 pb-1 transition duration-300 mt-1 mx-4`;

// const SocialLinksContainer = tw.div`mt-10`;
// const SocialLink = styled.a`
//   ${tw`cursor-pointer inline-block text-gray-100 hover:text-gray-500 transition duration-300 mx-4`}
//   svg {
//     ${tw`w-5 h-5`}
//   }
// `;

const CopyrightText = tw.p`text-center mt-10 font-medium tracking-wide text-sm text-gray-600`
export default () => {
  return (
    <Container>
      <Content>
        <Row>
          <LogoContainer>
            <a href="/">
            <LogoImg src={logo} alt="Logo" />
            </a>
          </LogoContainer>
          <LinksContainer>
            <Link href="/aboutus">About</Link>
            <Link href="/industries">Industries</Link>
            <Link href="/services">Services</Link>
            <Link href="/contact">Contact</Link>
            <Link href="/blog">Blogs</Link>
          </LinksContainer>
          {/* <SocialLinksContainer>
            <SocialLink href="#FB">
              <FacebookIcon />
            </SocialLink>
            <SocialLink href="#X">
              <TwitterIcon />
            </SocialLink>
            <SocialLink href="YT">
              <YoutubeIcon />
            </SocialLink>
          </SocialLinksContainer> */}
          <CopyrightText>
            &copy; Copyright {new Date().getFullYear()}, LariatCo. All Rights Reserved.
          </CopyrightText>
          <LegalContainer>
            <LegalLink href="/privacy-policy">Privacy</LegalLink>
            <LegalLink href="/terms-and-conditions">Terms & Conditions</LegalLink>            
          </LegalContainer>
        </Row>
      </Content>
    </Container>
  );
};
